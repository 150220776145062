import React, { useState, useEffect, useMemo, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	InputAdornment,
	Autocomplete,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import { User } from "./UserList"; // Import the User interface
import { OnboardingData } from "../../models/onboarding";
import { bindActionCreators } from "../../actions/actionCreators";
import * as adminActions from "../../actions/adminActions";
import { AppContext } from "../../models/applicationState";
import { TodoContext } from "../todoContext";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { StyledTextField } from "../../ux/adminDashboardStyles";

interface EditUserModalProps {
	open: boolean;
	onClose: () => void;
	onSave: (user: User) => void;
	user: User | null; // User to edit or null if creating new
}

const EditUserModal: React.FC<EditUserModalProps> = ({
	open,
	onClose,
	onSave,
	user,
}) => {
	const [editedUser, setEditedUser] = useState<User | null>(null);
	const [onboardingData, setOnboardingData] = useState<OnboardingData | null>(
		null
	);
	const [isOnboardingDataVisible, setIsOnboardingDataVisible] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState<User[]>([]);
	const [isSearching, setIsSearching] = useState(false);

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			admin: bindActionCreators(
				adminActions,
				appContext.dispatch
			) as unknown as adminActions.AdminActions,
		}),
		[appContext.dispatch]
	);

	const fetchOnboardingData = async () => {
		if (!user) {
			return;
		}
		const data = await actions.admin.getOnboardingDataByUid(user?.uid);
		setOnboardingData(data);
	};

	useEffect(() => {
		if (user) {
			setEditedUser({
				...user,
				createdDate: user.createdDate ? new Date(user.createdDate) : undefined,
			});
			fetchOnboardingData();
		}
	}, [user]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (searchTerm) {
				performSearch(searchTerm);
			}
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [searchTerm]);

	const performSearch = async (term: string) => {
		setIsSearching(true);
		// Perform the search operation here, e.g., calling an API to search for users by email
		// This is a placeholder function call, replace with your actual search API call
		const results = await actions.admin.searchUsersByEmail(term);
		setSearchResults(results as User[]);
		setIsSearching(false);
	};

	const handleSelectUser = async (selectedUserId: string) => {
		// Fetch the onboarding data for the selected user and populate the form
		const data = await actions.admin.getOnboardingDataByUid(selectedUserId);
		setOnboardingData(data);
		// You may also want to close the search result list or perform other actions
	};

	const handleChangeUser = (
		e: React.ChangeEvent<{ name?: string; value: unknown }> | SelectChangeEvent
	) => {
		const name = e.target.name as keyof typeof editedUser;
		const value = e.target.value;
		setEditedUser((prevUser) =>
			prevUser ? { ...prevUser, [name]: value } : null
		);
	};

	const handleChangeIsSubscribed = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setEditedUser((prevUser) =>
			prevUser ? { ...prevUser, isSubscribed: event.target.checked } : null
		);
	};

	const handleChangeOnboardingData = (
		e: React.ChangeEvent<{ name?: string; value: unknown }>
	) => {
		const name = e.target.name as keyof OnboardingData;
		const value = e.target.value;
		setOnboardingData((prevData) => ({
			...prevData, // Spread the previous data to keep all existing data
			[name]: value, // Update only the changed property
		}));
	};

	const handleChangeCreatedDate = (date: Date | null) => {
		setEditedUser((prevUser) =>
			prevUser ? { ...prevUser, createdDate: date || undefined } : null
		);
	};

	const handleSave = async () => {
		if (editedUser) {
			const formattedUser: User = {
				...editedUser,
				createdDate: editedUser.createdDate
					? new Date(editedUser.createdDate)
					: undefined,
			};
			onSave(formattedUser);
			if (onboardingData) {
				await actions.admin.editOnboardingDataByUid(
					editedUser.uid,
					onboardingData
				);
			}
		}
	};

	const createColorCircle = (color: string) => (
		<InputAdornment position="start">
			<span
				style={{
					display: "inline-block",
					width: "24px",
					height: "24px",
					borderRadius: "50%",
					backgroundColor: color,
					marginRight: "10px",
				}}
			/>
		</InputAdornment>
	);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Edit User</DialogTitle>
			<DialogContent>
				<FormControlLabel
					control={
						<Checkbox
							checked={editedUser?.isSubscribed || false}
							onChange={handleChangeIsSubscribed}
							name="isSubscribed"
						/>
					}
					label="Subscribed"
				/>
				{/* Add form fields for each user property */}
				<StyledTextField
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
					variant="outlined"
					// change text color to black

					name="name"
					value={editedUser?.name || ""}
					onChange={handleChangeUser}
				/>
				<StyledTextField
					margin="dense"
					id="email"
					label="Email"
					type="email"
					fullWidth
					variant="outlined"
					name="email"
					value={editedUser?.email || ""}
					onChange={handleChangeUser}
				/>
				<StyledTextField
					margin="dense"
					id="phone"
					label="Phone"
					type="text"
					fullWidth
					variant="outlined"
					name="phone"
					value={editedUser?.phone || ""}
					onChange={handleChangeUser}
				/>
				<StyledTextField
					margin="dense"
					id="companyName"
					label="Company Name"
					type="text"
					fullWidth
					variant="outlined"
					name="companyName"
					value={editedUser?.companyName || ""}
					onChange={handleChangeUser}
				/>
				<StyledTextField
					margin="dense"
					id="roleOnTeam"
					label="Role on Team"
					type="text"
					fullWidth
					variant="outlined"
					name="roleOnTeam"
					value={editedUser?.roleOnTeam || ""}
					onChange={handleChangeUser}
				/>
				<StyledTextField
					margin="dense"
					id="teamSize"
					label="Team Size"
					type="number"
					fullWidth
					variant="outlined"
					name="teamSize"
					value={editedUser?.teamSize || ""}
					onChange={handleChangeUser}
				/>

				<FormControl fullWidth margin="dense" variant="outlined">
					<InputLabel id="role-label">User Role</InputLabel>
					<Select
						labelId="role-label"
						id="role"
						value={editedUser?.role || ""}
						onChange={handleChangeUser}
						label="User Role"
						name="role"
						sx={{ color: "black" }}
					>
						<MenuItem value="user">User</MenuItem>
						<MenuItem value="admin">Admin</MenuItem>
					</Select>
				</FormControl>

				{/* Search Field for Importing Onboarding Data */}
				<Typography sx={{ mt: 3, mb: 2 }}>Import Onboarding Data</Typography>
				<Autocomplete
					id="searchUserByEmail"
					freeSolo
					options={searchResults.map((option) => option.email)}
					onInputChange={(_, value) => setSearchTerm(value)}
					onChange={(_, value) => {
						const selectedUser = searchResults.find(
							(user) => user.email === value
						);
						if (selectedUser) {
							handleSelectUser(selectedUser.uid);
						}
					}}
					renderInput={(params) => (
						<StyledTextField
							{...params}
							label="Search User by Email"
							margin="dense"
							variant="outlined"
							fullWidth
						/>
					)}
				/>
				{/* Onboarding Data Section */}
				<Accordion
					expanded={isOnboardingDataVisible}
					onChange={() => setIsOnboardingDataVisible(!isOnboardingDataVisible)}
				>
					<AccordionSummary
						expandIcon={<GridExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>Onboarding Data</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{/* section title About Brand */}
						<Typography variant="h6">About Brand</Typography>
						<StyledTextField
							margin="dense"
							id="aboutBrand"
							label="About Brand"
							type="text"
							fullWidth
							variant="outlined"
							name="aboutBrand"
							value={onboardingData?.aboutBrand || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Business Goals</Typography>
						<StyledTextField
							margin="dense"
							id="businessGoals"
							label="Business Goals"
							type="text"
							fullWidth
							variant="outlined"
							name="businessGoals"
							value={onboardingData?.businessGoals || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Competitors</Typography>
						<StyledTextField
							margin="dense"
							id="competitor1"
							label="Competitor 1"
							type="text"
							fullWidth
							variant="outlined"
							name="competitor1"
							value={onboardingData?.competitor1 || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="competitor2"
							label="Competitor 2"
							type="text"
							fullWidth
							variant="outlined"
							name="competitor2"
							value={onboardingData?.competitor2 || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="competitor3"
							label="Competitor 3"
							type="text"
							fullWidth
							variant="outlined"
							name="competitor3"
							value={onboardingData?.competitor3 || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Ideal Customer</Typography>
						<StyledTextField
							margin="dense"
							id="idealCustomer"
							label="Ideal Customer"
							type="text"
							fullWidth
							variant="outlined"
							name="idealCustomer"
							value={onboardingData?.idealCustomer || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Social Media</Typography>
						<StyledTextField
							margin="dense"
							id="facebook"
							label="Facebook"
							type="text"
							fullWidth
							variant="outlined"
							name="facebook"
							value={onboardingData?.facebook || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="instagram"
							label="Instagram"
							type="text"
							fullWidth
							variant="outlined"
							name="instagram"
							value={onboardingData?.instagram || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="tiktok"
							label="Tiktok"
							type="text"
							fullWidth
							variant="outlined"
							name="tiktok"
							value={onboardingData?.tiktok || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="linkedin"
							label="Linkedin"
							type="text"
							fullWidth
							variant="outlined"
							name="linkedin"
							value={onboardingData?.linkedin || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="pinterest"
							label="Pinterest"
							type="text"
							fullWidth
							variant="outlined"
							name="pinterest"
							value={onboardingData?.pinterest || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="youtube"
							label="Youtube"
							type="text"
							fullWidth
							variant="outlined"
							name="youtube"
							value={onboardingData?.youtube || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="x"
							label="X"
							type="text"
							fullWidth
							variant="outlined"
							name="x"
							value={onboardingData?.x || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Expansion Plan</Typography>
						<StyledTextField
							margin="dense"
							id="expansionPlan"
							label="Expansion Plan"
							type="text"
							fullWidth
							variant="outlined"
							name="expansionPlan"
							value={onboardingData?.expansionPlan || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Products and Services</Typography>
						<StyledTextField
							margin="dense"
							id="flagshipProductsServices"
							label="Flagship Products/Services"
							type="text"
							fullWidth
							variant="outlined"
							name="flagshipProductsServices"
							value={onboardingData?.flagshipProductsServices || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Least Profitable Offerings</Typography>
						<StyledTextField
							margin="dense"
							id="leastProfitableOfferings"
							label="Least Profitable Offerings"
							type="text"
							fullWidth
							variant="outlined"
							name="leastProfitableOfferings"
							value={onboardingData?.leastProfitableOfferings || ""}
							onChange={handleChangeOnboardingData}
						/>
						<Typography variant="h6">Brand Style</Typography>
						<StyledTextField
							margin="dense"
							id="primaryTypeface"
							label="Primary Typeface"
							type="text"
							fullWidth
							variant="outlined"
							name="primaryTypeface"
							value={onboardingData?.primaryTypeface || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="secondaryTypeface"
							label="Secondary Typeface"
							type="text"
							fullWidth
							variant="outlined"
							name="secondaryTypeface"
							value={onboardingData?.secondaryTypeface || ""}
							onChange={handleChangeOnboardingData}
						/>
						<StyledTextField
							margin="dense"
							id="primaryColor"
							label="Primary Color"
							type="text"
							fullWidth
							variant="outlined"
							name="primaryColor"
							value={onboardingData?.primaryColor || ""}
							onChange={handleChangeOnboardingData}
							InputProps={{
								startAdornment: createColorCircle(
									onboardingData?.primaryColor || ""
								),
							}}
						/>
						<StyledTextField
							margin="dense"
							id="secondaryColor"
							label="Secondary Color"
							type="text"
							fullWidth
							variant="outlined"
							name="secondaryColor"
							value={onboardingData?.secondaryColor || ""}
							onChange={handleChangeOnboardingData}
							InputProps={{
								startAdornment: createColorCircle(
									onboardingData?.secondaryColor || ""
								),
							}}
						/>
						<StyledTextField
							margin="dense"
							id="tertiaryColor"
							label="Tertiary Color"
							type="text"
							fullWidth
							variant="outlined"
							name="tertiaryColor"
							value={onboardingData?.tertiaryColor || ""}
							onChange={handleChangeOnboardingData}
							InputProps={{
								startAdornment: createColorCircle(
									onboardingData?.tertiaryColor || ""
								),
							}}
						/>
					</AccordionDetails>
				</Accordion>

				{/* Add the DatePicker for createdDate */}
				<Typography
					variant="subtitle1"
					style={{ marginTop: "20px", marginBottom: "10px" }}
				>
					Created Date
				</Typography>
				<DatePicker
					selected={editedUser?.createdDate}
					onChange={handleChangeCreatedDate}
					// showTimeSelect
					timeFormat="HH:mm"
					timeIntervals={15}
					timeCaption="time"
					dateFormat="MMMM d, yyyy h:mm aa"
					customInput={
						<StyledTextField
							fullWidth
							variant="outlined"
							InputLabelProps={{ shrink: true }}
						/>
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleSave}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditUserModal;
