import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { keyframes } from "@emotion/react";
import loader from "../assets/loader.svg";
import loaderDark from "../assets/loader-dark.svg";

interface LoaderProps {
	icon?: JSX.Element;
	text?: string;
	sx?: SxProps;
}

const dotAnimation = keyframes`
    0%, 20% { content: '.'; }
    40% { content: '..'; }
    60% { content: '...'; }
    80%, 100% { content: ''; }
`;

const Loader: React.FC<LoaderProps> = ({ icon, text, sx }) => {
	const theme = useTheme();
	const themeType = theme.palette.mode;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100%",
				...sx,
			}}
		>
			{icon && <Box sx={{ mb: 2 }}>{icon}</Box>}
			<img src={themeType === "light" ? loaderDark : loader} alt="Loader" />
			<Typography sx={{ mt: 2, position: "relative" }}>
				{text || "Doing the thing"}
				<Box
					component="span"
					sx={{
						position: "absolute",
						left: "100%",
						whiteSpace: "pre",
						"::after": {
							content: '""',
							animation: `${dotAnimation} 1.5s infinite step-start`,
						},
					}}
				/>
			</Typography>
		</Box>
	);
};

export default Loader;
