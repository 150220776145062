import React, { useContext, useMemo, useState } from "react";
import {
	Dialog,
	List,
	ListItem,
	ListItemText,
	IconButton,
	DialogTitle,
	DialogContent,
	useTheme,
	useMediaQuery,
	Snackbar,
	Collapse,
} from "@mui/material";
import { sendPasswordResetEmail } from "firebase/auth";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { STRIPE_CUSTOMER_PORTAL_URL } from "../../utils/constants";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useCustomUserData from "../../hooks/useCustomUserData";
import PersonalInfoForm from "./PersonalInformation";

interface FullScreenDialogProps {
	open: boolean;
	onClose: () => void;
}

const FullScreenDialog: React.FC<FullScreenDialogProps> = ({
	open,
	onClose,
}) => {
	const [message, setMessage] = useState("");
	const [showPersonalInfoForm, setShowPersonalInfoForm] = useState(false);
	const theme = useTheme();
	const { logout } = useAuth();
	const navigate = useNavigate();
	const { customUserData, setCustomUserData } = useCustomUserData();
	// const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const fullScreen = true;

	const handleBillingClick = () => {
		const link = document.createElement("a");
		link.href = STRIPE_CUSTOMER_PORTAL_URL; // TODO: Replace with production Stripe Customer Portal URL
		link.target = "_blank";
		link.rel = "noopener noreferrer";
		link.click();
	};

	const handlePersonalInfoClick = () => {
		setShowPersonalInfoForm((prevState) => !prevState);
	};

	const handleUserDataUpdated = (newUserData: any) => {
		// Update the state with the new user data, this should trigger a re-render
		// This assumes useCustomUserData provides a method to update the user data in context
		setCustomUserData(newUserData); // You need to provide such a method in your custom hook
		setShowPersonalInfoForm(false); // Close the form
	};

	const handleLogout = () => {
		logout(() => {
			// Perform actions after logout, like navigation
			navigate("/");
		});
	};

	const handleFeedbackClick = () => {
		const email = "support@cockpit-ai.com"; // replace with your feedback email
		const subject = encodeURIComponent("User Feedback/Support Request");
		const body = encodeURIComponent(
			`User Details:\nName: ${customUserData?.name}\nEmail: ${customUserData?.email}\n\nFeedback/Support Request:`
		);
		const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

		const link = document.createElement("a");
		link.href = mailtoLink;
		link.target = "_blank"; // This line is added
		link.click();
	};

	const items = [
		{
			label: "Personal Information",
			onClick: handlePersonalInfoClick,
		},
		// { label: "Appearance", onClick: () => console.log("Appearance") },
		{ label: "Billing", onClick: handleBillingClick },
		{ label: "Feedback", onClick: handleFeedbackClick },

		{ label: "Logout", onClick: handleLogout },
	];

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={onClose}
			PaperProps={{
				style: {
					// color: "white",
					paddingTop: "2rem",
				},
			}}
		>
			<DialogTitle
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					padding: theme.spacing(1),
					"& .MuiIconButton-root": {
						position: "absolute",
						left: theme.spacing(2),
					},
				}}
			>
				<IconButton
					edge="start"
					color="inherit"
					onClick={onClose}
					aria-label="back"
					sx={{
						marginRight: theme.spacing(1),
						fontSize: "0.9rem",
						color: "#7777AB",
					}}
				>
					<ArrowBackIosIcon
						sx={{
							fontSize: "1.5rem",
						}}
					/>
					back
				</IconButton>
				Settings
			</DialogTitle>
			<DialogContent>
				<List
					sx={{
						borderRadius: "0.7rem",
						marginTop: "0.5rem",
						padding: "0.7rem",
						maxWidth: "500px", // Set a max-width
						margin: "0 auto",
					}}
				>
					{items.map((item, index) => (
						<React.Fragment key={index}>
							<ListItem
								key={item.label}
								sx={{
									// background: "#202029",
									borderRadius: "0.7rem",
									marginTop: "0.5rem",
									padding: "0.7rem",
									border: "1px solid #29273E",
								}}
								button
								onClick={item.onClick}
							>
								<ListItemText primary={item.label} />
								<IconButton edge="end" color="inherit" aria-label="forward">
									{item.label === "Personal Information" &&
									showPersonalInfoForm ? (
										<KeyboardArrowDownIcon
											sx={{
												fontSize: "1.1rem",
											}}
										/>
									) : (
										<ArrowForwardIosIcon
											sx={{
												fontSize: "1.1rem",
											}}
										/>
									)}
								</IconButton>
							</ListItem>
							{item.label === "Personal Information" && (
								<Collapse
									in={showPersonalInfoForm}
									timeout="auto"
									unmountOnExit
								>
									<PersonalInfoForm
										initialData={{
											name: customUserData?.name || "",
											phone: customUserData?.phone || "",
											email: customUserData?.email || "",
											companyName: customUserData?.companyName || "",
										}}
										onUserDataUpdated={handleUserDataUpdated}
									/>
								</Collapse>
							)}
						</React.Fragment>
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
};

export default FullScreenDialog;
