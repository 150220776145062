import React, { useContext, useMemo, useState, useEffect } from "react";
import {
	Box,
	Button,
	TextField,
	Typography,
	Alert,
	Snackbar,
	useTheme,
	useMediaQuery,
	Grid,
} from "@mui/material";
import PhoneInput, { type Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as userActions from "../../actions/userActions";
import { PostSignupContext } from "../../context/PostSingupContext";
import { StepIndicator } from "./PostSignupStepIndicator";
import IllustrationImage from "../../assets/side_postSignup.png";
import { bindActionCreators } from "../../actions/actionCreators";
import { TodoContext } from "../todoContext";

export const PostSignupStepTwo = () => {
	const { dispatch } = useContext(TodoContext);
	const { postSignupData, setPostSignupData, setCurrentStep } =
		useContext(PostSignupContext);
	const [yourName, setYourName] = useState(postSignupData.name || "");
	const [phone, setPhone] = useState<Value>(
		(postSignupData.phone as Value) || ("" as Value)
	);
	const [companyName, setCompanyName] = useState(
		postSignupData.companyName || ""
	);
	const [websiteURL, setWebsiteURL] = useState(postSignupData.websiteURL || "");
	const [teamSize, setTeamSize] = useState(postSignupData.teamSize || "");
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const [validationErrors, setValidationErrors] = useState({
		yourName: false,
		companyName: false,
		websiteURL: false,
	});

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userData = await actions.users.getMe();
				setYourName(userData.name || "");
				setPhone((userData.phone as unknown as Value) || ("" as Value));
				setCompanyName(userData.companyName || "");
				setWebsiteURL(postSignupData.websiteURL || "");
				setTeamSize(userData.teamSize || "");
			} catch (error) {
				console.error("Failed to fetch user data:", error);
			}
		};

		fetchUserData();
	}, [actions.users]);

	const validateNameOrCompanyName = (value: string) => {
		return value.length >= 3;
	};

	const validateWebsiteURL = (value: string) => {
		const pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // fragment locator
		return !!pattern.test(value);
	};

	const handleCompanyNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const isValid = validateNameOrCompanyName(event.target.value);
		setValidationErrors({ ...validationErrors, companyName: !isValid });
		setCompanyName(event.target.value);
	};

	const handleWebsiteURLChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = event.target.value;
		const isValid = value === "" || validateWebsiteURL(value);
		setValidationErrors({ ...validationErrors, websiteURL: !isValid });
		setWebsiteURL(value);
	};

	const handleTeamSizeChange = (size: string) => {
		setTeamSize(size);
	};

	const handleYourNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isValid = validateNameOrCompanyName(event.target.value);
		setValidationErrors({ ...validationErrors, yourName: !isValid });
		setYourName(event.target.value);
	};

	const handlePhoneChange = (value?: Value) => {
		setPhone(value || ("" as Value));
	};

	const handleContinue = async () => {
		const hasErrors = Object.values(validationErrors).some((error) => error);
		if (!yourName || !companyName || !teamSize || hasErrors) {
			setOpenSnackbar(true);
			return;
		}
		setPostSignupData({
			...postSignupData,
			companyName: companyName,
			websiteURL: websiteURL,
			teamSize: teamSize,
			name: yourName,
			phone: phone ? phone.toString() : "",
		});
		await actions.users.completeSignUp({
			...postSignupData,
			companyName: companyName,
			websiteURL: websiteURL,
			teamSize: teamSize,
			name: yourName,
			phone: phone ? phone.toString() : "",
		});
		setCurrentStep(3);
	};

	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	return (
		<Grid container spacing={isDesktop ? 4 : 0}>
			<Grid item xs={12} md={7}>
				<Box
					sx={{
						maxWidth: isDesktop ? "100%" : 400,
						width: isDesktop ? "100%" : "auto",
						mx: "auto",
						padding: isDesktop ? 6 : 4,
						textAlign: "center",
						overflowY: "auto",
						overflowX: "hidden",
						maxHeight: "100vh",
						boxSizing: "border-box",
					}}
				>
					{!isDesktop && <StepIndicator />}
					<Typography variant="h4" sx={{ mb: 4, mt: 4, textAlign: "left" }}>
						You & Your Company
					</Typography>
					<Box
						component="form"
						noValidate
						autoComplete="off"
						sx={{
							"& .MuiTextField-root": { mb: 2 },
							maxWidth: isDesktop ? "90vw" : "100%",
							mx: "auto",
							padding: 2,
							textAlign: "center",
							color: theme.palette.primary.contrastText,
						}}
					>
						<TextField
							fullWidth
							label="Your name"
							value={yourName}
							onChange={handleYourNameChange}
							error={validationErrors.yourName}
							helperText={
								validationErrors.yourName
									? "Name must be at least 3 characters long"
									: ""
							}
						/>
						<Box sx={{ width: "100%", ".PhoneInput": { mb: 2 } }}>
							<PhoneInput
								fullWidth
								label="Phone"
								value={phone}
								onChange={handlePhoneChange}
								countryCallingCodeEditable={false}
								isNumericOnly
								defaultCountry="US"
							/>
						</Box>
						<TextField
							fullWidth
							label="Company name"
							value={companyName}
							onChange={handleCompanyNameChange}
							error={validationErrors.companyName}
							helperText={
								validationErrors.companyName
									? "Company name must be at least 3 characters long"
									: ""
							}
						/>
						<TextField
							fullWidth
							label="Website URL"
							value={websiteURL}
							onChange={handleWebsiteURLChange}
							error={validationErrors.websiteURL}
							helperText={
								validationErrors.websiteURL
									? "Please enter a valid website URL"
									: ""
							}
						/>
						<Box sx={{ my: 2, textAlign: "left" }}>
							<Typography variant="subtitle1" gutterBottom>
								Your team size
							</Typography>
							<Box>
								<Button
									variant={teamSize === "1" ? "contained" : "outlined"}
									sx={{
										border: "1px solid #29273E",
										color:
											teamSize === "1"
												? "secondary.contastText"
												: "primary.contastText",
										width: "fit-content",
										height: "2rem",
										borderRadius: "1rem",
										mr: 1,
										mt: 1,
									}}
									onClick={() => handleTeamSizeChange("1")}
								>
									1-5
								</Button>
								<Button
									variant={teamSize === "2" ? "contained" : "outlined"}
									onClick={() => handleTeamSizeChange("2")}
									sx={{
										border: "1px solid #29273E",
										color:
											teamSize === "2"
												? "secondary.contastText"
												: "primary.contastText",
										width: "fit-content",
										height: "2rem",
										borderRadius: "1rem",
										mr: 1,
										mt: 1,
									}}
								>
									6-10
								</Button>
								<Button
									variant={teamSize === "3" ? "contained" : "outlined"}
									onClick={() => handleTeamSizeChange("3")}
									sx={{
										border: "1px solid #29273E",
										color:
											teamSize === "3"
												? "secondary.contastText"
												: "primary.contastText",
										width: "fit-content",
										height: "2rem",
										borderRadius: "1rem",
										mr: 1,
										mt: 1,
									}}
								>
									11-15
								</Button>
								<Button
									variant={teamSize === "4" ? "contained" : "outlined"}
									onClick={() => handleTeamSizeChange("4")}
									sx={{
										border: "1px solid #29273E",
										color:
											teamSize === "4"
												? "secondary.contastText"
												: "primary.contastText",
										width: "fit-content",
										height: "2rem",
										borderRadius: "1rem",
										mr: 1,
										mt: 1,
									}}
								>
									15+
								</Button>
							</Box>
						</Box>
					</Box>

					<Snackbar
						open={openSnackbar}
						autoHideDuration={6000}
						onClose={handleCloseSnackbar}
					>
						<Alert
							onClose={handleCloseSnackbar}
							severity="error"
							sx={{ width: "100%" }}
						>
							Please fill out all fields before continuing.
						</Alert>
					</Snackbar>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mt: 6,
							padding: isDesktop ? "0 4rem" : "0 1rem",
							width: isDesktop ? "100%" : "auto",
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-start",
								width: "50%",
							}}
						>
							<Button
								variant="outlined"
								onClick={() => setCurrentStep(1)}
								sx={{
									mr: 1,
									borderColor: "#29273E",
									color: "primary.contrastText",
								}}
							>
								Back
							</Button>
							<Button
								variant="contained"
								onClick={handleContinue}
								sx={{ ml: 1, color: theme.palette.secondary.contrastText }}
							>
								Continue
							</Button>
						</Box>
						{isDesktop && <StepIndicator />}
					</Box>
				</Box>
			</Grid>
			{isDesktop && (
				<Grid item md={5}>
					<Box
						sx={{
							position: "fixed",
							right: "0.5rem",
							top: "5rem",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "calc(100vh - 6rem)",
						}}
					>
						<img
							src={IllustrationImage}
							alt="Illustration"
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
								borderRadius: 2,
								height: "90%",
							}}
						/>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};
