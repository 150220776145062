import React, { useState } from "react";
import {
	TextField,
	Alert,
	Typography,
	useTheme,
	Link,
	IconButton,
	InputAdornment,
} from "@mui/material";
import {
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import useCustomUserData from "../hooks/useCustomUserData";
import {
	BoxStyled,
	ButtonContainerStyled,
	ButtonStyled,
} from "../ux/mainAuthPageStyles";
import logo from "../assets/Vector.svg";
import logoLight from "../assets/VectorLight.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SignInForm = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	// const { isProfileComplete, isLoading } = useCustomUserData();

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const navigate = useNavigate();

	// useEffect(() => {
	// 	if (!isLoading) {
	// 		if (!isProfileComplete) {
	// 			navigate("/post-signup");
	// 		} else {
	// 			// navigate('/dashboard');
	// 		}
	// 	}
	// }, [isProfileComplete, isLoading, navigate]);

	const signIn = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password);
			navigate("/");
		} catch (error) {
			setError((error as Error).message);
		}
	};

	const handleForgotPassword = async () => {
		if (!email) {
			setError("Please enter your email address");
			return;
		}
		try {
			await sendPasswordResetEmail(auth, email);
			setMessage("Password reset email sent. Please check your inbox.");
			setError("");
		} catch (error) {
			setError((error as Error).message);
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<BoxStyled
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
				maxWidth: 400,
				margin: "0 auto",
			}}
		>
			{error && <Alert severity="error">{error}</Alert>}
			{message && <Alert severity="success">{message}</Alert>}
			<img
				src={themeType === "dark" ? logo : logoLight}
				alt="Logo"
				style={{ width: "8rem", marginTop: "2rem" }}
			/>
			<ButtonContainerStyled>
				<Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
					Login with email and password
				</Typography>
				<TextField
					label="Email address"
					type="email"
					value={email}
					fullWidth
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					label="Password"
					type={showPassword ? "text" : "password"}
					value={password}
					fullWidth
					onChange={(e) => setPassword(e.target.value)}
					autoComplete="current-password"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={togglePasswordVisibility} edge="end">
									{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<ButtonStyled variant="contained" onClick={signIn} sx={{ mt: 1 }}>
					<Typography variant="button" sx={{ color: "text.secondary" }}>
						Login
					</Typography>
				</ButtonStyled>
				<Link
					component="button"
					variant="body2"
					onClick={handleForgotPassword}
					sx={{ mt: 1, cursor: "pointer", color: "text.primary" }}
				>
					Forgot Password?
				</Link>
			</ButtonContainerStyled>
		</BoxStyled>
	);
};

export default SignInForm;
